import { Options as PopperOptions } from '@popperjs/core'

/**
 * Popper config of BaseSearchPopover.vue
 */
export const popperOptions: Partial<PopperOptions> = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-27, 13],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 32,
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start'],
      },
    },
  ],
}
