<script setup lang="ts">
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { createPopper, Instance as PopperInstance } from '@popperjs/core'
  import { ref, VNode, watch } from 'vue'
  import { popperOptions } from '@/modules/base/config/baseSearchPopoverConfig'
  import { unwrapClassPropGetter, ClassPropOrGetter } from '../types/props'

  withDefaults(
    defineProps<{
      disabled?: boolean
      buttonClass?: ClassPropOrGetter<boolean>
    }>(),
    {
      disabled: false,
      buttonClass: '',
    }
  )

  const button = ref<VNode>()
  const panel = ref<VNode>()
  const popper = ref<PopperInstance>()

  watch(
    [() => button.value?.el, () => panel.value?.el],
    ([button, panel]) => {
      const buttonElement = button as HTMLElement
      const panelElement = panel as HTMLElement

      popper.value?.destroy()

      if (buttonElement && panelElement) {
        popper.value = createPopper(
          buttonElement?.querySelector('[data-popper-target]') ?? buttonElement,
          panelElement,
          popperOptions
        )
      }
    },
    {
      immediate: true,
    }
  )
</script>

<template>
  <Popover v-slot="{ open, close }">
    <PopoverButton
      ref="button"
      class="base-control disabled:not-allowed hover:opacity-control w-full p-0"
      :class="unwrapClassPropGetter(buttonClass, open)"
      data-testid="base-popover-button"
      :disabled
    >
      <slot name="button" v-bind="{ open }" />
    </PopoverButton>
    <teleport to="body">
      <PopoverPanel
        ref="panel"
        class="bg-black-var border-gray-var-700 z-40 rounded-lg border"
        data-testid="popover-panel"
        :focus="false"
      >
        <div class="gc-arrow" data-popper-arrow />

        <slot name="panel" v-bind="{ close }" />
      </PopoverPanel>
    </teleport>
  </Popover>
</template>

<style scoped>
  .gc-arrow {
    @apply -z-10 h-3 w-3;

    &::after {
      content: '';
      @apply bg-black-var border-gray-var-700 block h-3 w-3 border-l border-t;
    }
  }

  [data-popper-placement^='top'] > .gc-arrow {
    @apply after:rotate-225 -bottom-1.5;
  }

  [data-popper-placement^='bottom'] > .gc-arrow {
    @apply -top-1.5 after:rotate-45;
  }

  [data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }
</style>
